import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

window.addEventListener("load", () => {
  // TagError(GoogleAdSenseの広告が表示できないエラー)
  // SecurityError(クロスドメインのなんか、YouTubeのiframeで発生するやつ)
  Sentry.init({
    dsn: document.getElementById('sentry_dsn').value,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: ["TagError", "SecurityError"]
  });
})
