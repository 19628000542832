// 本番環境でconsole.logを表示しない
window.console = {};
window.console.log = function (i) {
  return;
};
window.console.time = function (i) {
  return;
};
window.console.timeEnd = function (i) {
  return;
};